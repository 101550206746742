<template>
<div class="chats-view">
    <vs-dialog blur v-model="emailModal">
        <template #header>
            <h1>{{ $t('Chat.emailModalTitle') }}</h1>
        </template>

        <p>
            {{ $t('Chat.emailModalMessage') }}
        </p>

        <PtyInput id="pty__Chat_email" placeholder="Email" block v-model="email" />

        <template #footer>
            <vs-row direction="column" style="gap: 1rem">
                <PtyButton id="pty__Chat_emailCancelButton" @click="saveParticipant" block>{{ $t('Chat.confirmImage') }}</PtyButton>
                <PtyButton id="pty__Chat_emailConfirmButton" @click="emailModal = false" light-blue block>{{ $t('Chat.cancelButtonLight') }}</PtyButton>
            </vs-row>
        </template>
    </vs-dialog>
    <PtyButton id="pty__Chats_titlePart1" style="position: absolute; top: 10px; right: 20px;" :disabled="newMatchesCount < 1" :animate="newMatchesCount > 0" :extra-shadow="newMatchesCount > 0" @click="goToMatches">
        <i class="bx bxs-heart"></i>
        <span style="margin-left: 5px;" v-if="newMatchesCount === -1">0</span>
        <span style="margin-left: 5px;" v-else-if="newMatchesCount < 11">{{ newMatchesCount }}</span>
        <span style="margin-left: 5px;" v-else>10+</span>
    </PtyButton>

    <div class="action-selection__content">
        <h1>{{ $t('Chats.titlePart1') }}<span>{{ $t('Chats.titlePart2') }}</span></h1>
        <p>
            {{ $t('Chats.message') }}
        </p>
    </div>
    <div class="chats-header">
            <PtyButton id="pty__Chats_matchesTab" transparent @click="selectedTab = 'matches'" :class="{disabled: selectedTab !== 'matches'}">
                <MatchesIcon />
                <p>{{ $t('Chats.matchesTab') }}</p>
            </PtyButton>

            <PtyButton id="pty__Chats_usersTab" transparent @click="selectedTab = 'users'" :class="{disabled: selectedTab !== 'users'}">
                <UsersIcon />
                <p>{{ $t('Chats.usersTab') }}</p>
            </PtyButton>
        </div>


        <div class="inboxes" v-if="selectedTab === 'matches'">
            <template v-if="matches != null">
                <PtyButton transparent block no-padding @click="openChat(match)" v-for="match in matches" :key="match.id" :disabled="match.participant && match.participant.is_blocked">
                    <div class="inbox">
                        <vs-avatar circle badge size="80" history style="min-width: 80px;">
                            <img :src="match.pictureUrl" />
                            <template #badge>
                                <img :src="match.tagIcon" style="width: 32px; height: 32px;" />
                            </template>
                        </vs-avatar>
                        <div class="right">
                            <div class="inbox__heading">
                                <h3>{{ match.participant.nickname || 'Unknown' }}</h3>
                                <p>
                                    <template v-if="match.chat && match.chat.latest_message && match.chat.latest_message && match.chat.latest_message.created_at && new Date(match.chat.latest_message.created_at).getFullYear() > 1970">
                                        {{ new Date(match.chat.latest_message.created_at).toLocaleTimeString() }}
                                    </template>

                                    
                                </p>
                            </div>
                            <div class="inbox__content">
                                <p>
                                    <template v-if="match.chat && match.chat.latest_message && match.chat.latest_message.payload && match.chat.latest_message.payload.text">
                                        {{ match.chat.latest_message.payload.text }}
                                    </template>

                                    <template v-else>
                                        {{ $t('Chats.noMessages') }}
                                    </template>
                                </p>
                                <template v-if="match.chat && match.chat.latest_message && match.chat.latest_message.payload && match.chat.latest_message.payload.text">
                                    <span class="unread" v-if="match.chat.latest_message.participant.id == match.participant.id && match.chat.latest_message.read_at == null"/>
                                </template>
                            </div>
                        </div>

                    </div>
                </PtyButton>

                <vs-row v-if="matches.length == 0" style="width: 100%;" justify="center" align="center">
                    <img src="../assets/images/illustrations/participants.png" style="width: 80%; margin-bottom: 10px;"/>
                    <h2 style="text-align: center;">
                        {{ $t('Chats.noMatchesYet') }}
                    </h2>
                </vs-row>
            </template>            
        </div>

        <div class="inboxes" v-else-if="selectedTab === 'users'" @scroll="handleInboxesLazyLoading">
            <template v-if="participants != null">
                <PtyButton transparent block no-padding @click="openChat(participant)" v-for="participant in participants" :key="participant.id" :disabled="participant.is_blocked">
                    <div class="inbox">
                        <vs-avatar circle badge size="80" history style="min-width: 80px;">
                            <img :src="participant.pictureUrl" />
                            <template #badge>
                                <img :src="tags[participant.tag]" style="width: 32px; height: 32px;" />
                            </template>
                        </vs-avatar>
                        <div class="right">
                            <div class="inbox__heading">
                                <h3>{{ participant.nickname || 'Unknown' }}</h3>
                                <p>
                                    <template v-if="participant.chat && participant.chat[0] && participant.chat[0].latest_message && participant.chat[0].latest_message.created_at && new Date(participant.chat[0].latest_message.created_at).getFullYear() > 1970">
                                        {{ new Date(participant.chat[0].latest_message.created_at).toLocaleTimeString() }}
                                    </template>
                                </p>
                            </div>
                            <div class="inbox__content">
                                <p>
                                    <template v-if="participant.chat && participant.chat[0] && participant.chat[0] && participant.chat[0].latest_message && participant.chat[0].latest_message.payload && participant.chat[0].latest_message.payload.text">
                                        {{ participant.chat[0].latest_message.payload.text }}
                                    </template>

                                    <template v-else>
                                        {{ $t('Chats.noMessages') }}
                                    </template>
                                </p>
                                <template v-if="participant.chat && participant.chat[0] && participant.chat[0].latest_message && participant.chat[0].latest_message.payload && participant.chat[0].latest_message.payload.text">
                                    <span class="unread" v-if="participant.chat[0].latest_message.participant.id == participant.id && participant.chat[0].latest_message.read_at == null"/>
                                </template>
                            </div>
                        </div>
                    </div>
                </PtyButton>

                <vs-row v-if="participants.length == 0" style="width: 100%;" justify="center" align="center">
                    <img src="../assets/images/illustrations/participants.png" style="width: 80%; margin-bottom: 10px;"/>
                    <h2 style="text-align: center;">
                        {{ $t('Chats.noParticipantsYet') }}
                    </h2>
                </vs-row>
            </template>

            <!-- if there is a next page show loading -->
            <vs-row v-if="participantsAfter != null" justify="center" align="center" style="width: 100%; padding: 1rem;">
                <i class="bx bx-loader-alt bx-spin" style="font-size: 2rem; color: rgba(var(--vs-primary));"></i>
            </vs-row>
        </div>
</div>
</template>

<script>
// @ts-ignore
import * as Sentry from '@sentry/vue';

import {GlobalEventEmitter} from '@/GlobalEventEmitter';
import {
    fileService,
    matchService,
    partecipantService
} from '@/rest';
import ArdenteIcon from '../assets/images/tags/ardente.png';
import DevilIcon from '../assets/images/tags/devil.png';
import DiscretoIcon from '../assets/images/tags/discreto.png';
import InnamoratoIcon from '../assets/images/tags/innamorato.png';
import InnocenteIcon from '../assets/images/tags/innocente.png';
import MaliziosoIcon from '../assets/images/tags/malizioso.png';
import QueenIcon from '../assets/images/tags/queen.png';
import RomanticoIcon from '../assets/images/tags/romantico.png';
import SuperdotatoIcon from '../assets/images/tags/superdotato.png';
import TimidoIcon from '../assets/images/tags/timido.png';

import MatchesIcon from '../assets/images/icons/matches.svg';
import UsersIcon from '../assets/images/icons/users.svg';

import PtyInput from '../components/PtyInput.vue';
import PtyButton from '../components/PtyButton.vue';
export default {
    name: 'Chats',
    components: {
        PtyButton,
        PtyInput,

        MatchesIcon,
        UsersIcon
    },
    data: () => ({
        selectedTab: 'matches',

        tags: {
            'ARDENTE': ArdenteIcon,
            'DEVIL': DevilIcon,
            'DISCRETO': DiscretoIcon,
            'INNAMORATO': InnamoratoIcon,
            'INNOCENTE': InnocenteIcon,
            'MALIZIOSO': MaliziosoIcon,
            'QUEEN': QueenIcon,
            'ROMANTICO': RomanticoIcon,
            'SUPERDOTATO': SuperdotatoIcon,
            'TIMIDO': TimidoIcon
        },

        matches: null,
        participants: null,

        participantsBefore: null,
        participantsAfter: null,
        participantsLoading: false,

        newMatchesCount: -1,
        newMatchesTimeout: null,

        emailModal: false,
        email: '',

        participant: null,

        loading: false,
    }),

    methods: {
        async saveParticipant() {
            const loading = this.$vs.loading({
                type: 'circle'
            });

            try {
                let participant = await partecipantService.detail(); // get current participant, we need to patch whole object
                participant.email = this.email;

                await partecipantService.edit(participant);

                this.emailModal = false;
            } catch (error) {
                Sentry.captureException(error);

                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('OnBoarding.messages.canNotSaveChanges'),
                    color: 'primary', duration: 5000,
                    position: 'top-center'
                });
            } finally {
                loading.close();
            }
        },

        async getMatchesCount() {
            try {
                let matches = await matchService.get({ // matches to approve
                    limit: 11,
                });

                if(matches.data != null) {
                    this.newMatchesCount = matches.data.length;
                } else {
                    this.newMatchesCount = 0;
                }
            } catch (e) {
                Sentry.captureException(e);
                console.error(e);
            }

            this.newMatchesTimeout = setTimeout(() => {
                this.getMatchesCount();
            }, 5000);
        },

        goToMatches() {
            this.$router.push({
                name: 'Matches'
            });
        },

        openChat(match) {
            const participantId = match.participant ? match.participant.id : (match.id || null); // if participant is null, then it's a match, otherwise it's a participant
            const chatId = match.chat ? match.chat.id : null;
            const matchId = match.participant ? match.id : null; // if participant is null, then it's a match, otherwise it's a participant
            this.$router.push({
                name: 'Chat',
                params: {
                    participantId,
                    chatId,
                    matchId,
                    participant: JSON.parse(JSON.stringify({
                        ...(match.participant || match),
                        pictureUrl: match.pictureUrl,
                    })),
                }
            });
        },

        async getMatched(id = undefined) {
            try {
                const matches = await matchService.getMatched({
                    limit: 200,
                    id,
                });
                let newMatches = matches.data || [];

                for (var i = 0; i < newMatches.length; i++) {
                    const match = newMatches[i];
                    match.tagIcon = this.tags[match.participant.tag];
                    if (match.pictureUrl == null) {
                        try {
                            const dlUrl = await fileService.getSignedDownloadURL({
                                name: match.participant.id,
                            })
                            match.pictureUrl = dlUrl.url;
                        } catch (e) {
                            Sentry.captureException(e);
                            console.error(e);
                        }
                    }
                }

                this.matches = newMatches;

            } catch (e) {
                Sentry.captureException(e);
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chats.canNotLoadMatches'),
                    position: 'top-center',
                    color: 'primary', duration: 5000,
                });
            }
        },

        async getParticipants({before = null, after = null, id = undefined} = {}) {
            try {
                const participantsResult = await partecipantService.get({
                    limit: 5, // TODO: Increase, but for testing pagination is fine
                    only_ready_to_chat: true,
                    before,
                    after,
                    order: 'desc(latest_message_time)',
                    id,
                });
                let participants = participantsResult.data || [];

                for (var i = 0; i < participants.length; i++) {
                    const participant = participants[i];
                    if (participant.pictureUrl == null) {
                        try {
                            const dlUrl = await fileService.getSignedDownloadURL({
                                name: participant.id,
                            })
                            participant.pictureUrl = dlUrl.url;
                        } catch (e) {
                            Sentry.captureException(e);
                            console.error(e);
                        }
                    }
                }

                if(id != undefined){
                    // find and replace participant
                    let part = this.participants.find(e => e.id === id);
                    if(part){
                        // remove
                        this.participants = this.participants.filter(e => e.id !== id);
                    }

                    // add in proper position
                    this.handleNewParticipantReadyToChat(participants[0]);
                } else {
                    if(this.participants != null){
                        this.participants = this.participants.concat(participants);
                    } else {
                        this.participants = participants;
                    }
                    this.participantsBefore = participantsResult.before;
                    this.participantsAfter = participantsResult.after;
                }

            } catch (e) {
                Sentry.captureException(e);
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chats.canNotLoadParticipants'),
                    position: 'top-center',
                    color: 'primary', duration: 5000,
                });
            }
        },

        async handleNewMatch(match){
            // dl profile picture
            if(match.pictureUrl == null){
                try {
                    const dlUrl = await fileService.getSignedDownloadURL({
                        name: match.participant.id,
                    })
                    match.pictureUrl = dlUrl.url;
                } catch (e) {
                    Sentry.captureException(e);
                    console.error(e);
                }
            }

            match.tagIcon = this.tags[match.participant.tag];

            // we need to find proper position to insert new match
            // we need to sort matches by latest message date or updated_at of match (timestamp when both participants liked each other)
            const matches_dates = this.matches.map(e => e.chat && e.chat.latest_message && e.chat.latest_message.created_at ? new Date(e.chat.latest_message.created_at) : new Date(e.updated_at));
            const new_match_date = new Date(match.updated_at);

            // find the position to insert new match
            let insert_position = 0;
            for(let i = 0; i < matches_dates.length; i++){
                if(matches_dates[i] < new_match_date){
                    insert_position = i;
                    break;
                }
            }

            // insert new match
            this.matches.splice(insert_position, 0, match);

            // select matches tab
            this.selectedTab = 'matches';
        },

        async handleNewParticipantReadyToChat(participant){
            // dl profile picture
            if(participant.pictureUrl == null){
                try {
                    const dlUrl = await fileService.getSignedDownloadURL({
                        name: participant.id,
                    })
                    participant.pictureUrl = dlUrl.url;
                } catch (e) {
                    Sentry.captureException(e);
                    console.error(e);
                }
            }

            participant.tagIcon = this.tags[participant.tag];

            // find the position to insert new participant, we need to sort participants by latest message date or ready_to_chat_at
            const participants_dates = this.participants.map(e => e.chat && e.chat[0] && e.chat[0].latest_message && e.chat[0].latest_message.created_at ? new Date(e.chat[0].latest_message.created_at) : new Date(e.ready_to_chat_at));
            const new_participant_date = new Date(participant.ready_to_chat_at);

            // find the position to insert new participant
            let insert_position = 0;
            for(let i = 0; i < participants_dates.length; i++){
                if(participants_dates[i] < new_participant_date){
                    insert_position = i;
                    break;
                }
            }

            // insert new participant
            this.participants.splice(insert_position, 0, participant);
        },

        async handleParticipantBlocked(participant){
            // find participant in matches and set that as blocked
            let match = this.matches.find(e => e.participant && e.participant.id === participant.id);
            if(match){
                match.participant.is_blocked = true;
            }

            // do the same in participants
            let part = this.participants.find(e => e.id === participant.id);
            if(part){
                part.is_blocked = true;
            }
        },

        feedMessageHandler(message){
            const data = JSON.parse(message.data);
            switch(data.type){
                case 'NEW_MATCHED':
                    this.handleNewMatch(data.match);
                    break;
                case 'PARTICIPANT_JOINED_READY_TO_CHAT':
                    this.handleNewParticipantReadyToChat(data.participant);
                    break;
                case 'PARTICIPANT_BLOCKED':
                    this.handleParticipantBlocked(data.blocked); // show blocked on both sides
                    this.handleParticipantBlocked(data.blocker);
                    break;
            }
        },

        chatMessageHandler(message) {
            const data = JSON.parse(message.data);
            const chatId = data.chat_id;

            // search for participant and set chat last message
            let match = this.matches.find(e => (e.chat && e.chat.id === chatId) || (e.participant && e.participant.id === data.from_participant_id));

            if(match){
                // remove it from matches
                this.matches.splice(this.matches.indexOf(match), 1);

                //match.chat.latest_message.payload.text
                if(match.chat == null){
                    match.chat = {};
                }

                if(match.chat.latest_message == null){
                    match.chat.latest_message = {};
                }

                if(match.chat.latest_message.payload == null){
                    match.chat.latest_message.payload = {};
                }

                match.chat.latest_message.payload.text = data.text;
                match.chat.latest_message.created_at = Date.now();
                match.chat.latest_message.read_at = null;
                match.chat.latest_message.participant = {
                    id: data.from_participant_id
                };

                console.log('new message for match', match);

                // add it on top
                this.matches.unshift(match);

                this.matches.__ob__.dep.notify();
            } else {
                this.getMatched();
            }

            // try to find in participants
            let participant = this.participants.find(e => (e.chat && e.chat[0] && e.chat[0].id === chatId) || (e.id === data.from_participant_id));
            if(participant){
                // remove it from participants
                this.participants.splice(this.participants.indexOf(participant), 1);

                if(participant.chat == null){
                    participant.chat = [];
                }

                if(participant.chat[0] == null){
                    participant.chat[0] = {};
                }

                if(participant.chat[0].latest_message == null){
                    participant.chat[0].latest_message = {};
                }

                if(participant.chat[0].latest_message.payload == null){
                    participant.chat[0].latest_message.payload = {};
                }

                participant.chat[0].latest_message.payload.text = data.text;
                participant.chat[0].latest_message.created_at = Date.now();
                participant.chat[0].latest_message.read_at = null;
                participant.chat[0].latest_message.participant = {
                    id: data.from_participant_id
                };

                console.log('new message for participant', participant);

                // add it on top
                this.participants.unshift(participant);

                this.participants.__ob__.dep.notify();
            } else {
                this.getParticipants(data.from_participant_id);
            }
        },

        handleEmailModal(){
            if(!this.participant){
                console.log('no participant');
                return;
            }

            let emailModal = JSON.parse(localStorage.getItem('EMAIL_MODAL'));

            if(emailModal == null){
                localStorage.setItem('EMAIL_MODAL', JSON.stringify({
                    shown: false,
                    chats_shown_at: Date.now(),
                }));
            } else {
                // if not shown yet and less than 30 minutes at the end of event are missing
                const eventsEndAt = new Date(this.participant.event.end_at);
                const remainingTime = eventsEndAt - Date.now();
                console.log('remaining time', remainingTime);
                if(emailModal.shown === false && remainingTime < 30 * 60 * 1000){
                    this.emailModal = true;
                    emailModal.shown = true;
                    localStorage.setItem('EMAIL_MODAL', JSON.stringify(emailModal));
                }
            }
        },

        async handleInboxesLazyLoading(e){
            if(this.participantsLoading){
                return;
            }
            this.participantsLoading = true; // handled by this call, prevent double calls
            try {
                // when scroll of inboxes is at the bottom, load more inboxes (participants)
                if(e && e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight){
                    if(this.selectedTab === 'users' && this.participantsAfter != null){
                        await this.getParticipants({
                            before: this.participantsBefore,
                            after: this.participantsAfter,
                        });
                    }
                }
            } catch(e){
                Sentry.captureException(e);
                console.error(e);
            }

            this.participantsLoading = false;
            
        },

        async initialLoad(){
            if(this.loading){
                console.log('already loading');
                return;
            }

            this.loading = true;

            this.matches = null;
            this.participants = null;

            try {
                await this.getMatched();
                if(this.matches.length == 0){
                    this.selectedTab = 'users';
                }
                await this.getParticipants();
            } catch(e){
                Sentry.captureException(e);
                console.error(e);
            }
            this.loading = false;
        }
    },

    async mounted() {
        await this.initialLoad();

        this.getMatchesCount();
        this.handleInboxesLazyLoading();

        try {
            this.participant = await partecipantService.detail(); // we need participant.event.end_at to show email modal
            console.log('participant', this.participant);
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }

        this.handleEmailModal();

        GlobalEventEmitter.$emit('ws-connect');
        GlobalEventEmitter.$on('chat-message', this.chatMessageHandler);
        GlobalEventEmitter.$on('feed-message', this.feedMessageHandler);

        GlobalEventEmitter.$on('chat-connected', () => {
            this.initialLoad();
        });

        GlobalEventEmitter.$on('feed-connected', () => {
            // WARNING: This is a workaround because both event are fired at the same time, it could be a race condition
            this.initialLoad();
        });
    },

    beforeDestroy() {
        GlobalEventEmitter.$off('chat-message', this.chatMessageHandler);
        GlobalEventEmitter.$off('feed-message', this.feedMessageHandler);

        GlobalEventEmitter.$off('chat-connected');
        GlobalEventEmitter.$off('feed-connected');

        if(this.newMatchesTimeout){
            clearTimeout(this.newMatchesTimeout);
            this.newMatchesTimeout = null;
        }
    },

    watch: {
        newMatchesCount(n, o){
            if(o == -1){
                return; // first load
            }

            if(n > o && n > 0){
                this.$vs.notification({
                    title: this.$t('Chats.newMatch'),
                    text: this.$t('Chats.newMatchMessage'),
                    position: 'top-center',
                    color: 'primary', duration: 5000,
                });
            }
        },

        matches(n, o){
            if(o && o.length == 0 && n && n.length > 0){ // if there were no matches and now there are, select matches tab
                this.selectedTab = 'matches';
            }
        }
    }
}
</script>

<style>
.inbox {
    padding: 10px 15px;
    width: 100%;
    box-sizing: border-box !important;
}
</style><style scoped>
.chats-view .inboxes .inbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chats-view .inboxes .inbox>* {
    /* i want all items fill height */
    height: 100%;
}

.chats-view .inboxes {
    width: 100%;
    overflow-y:auto!important;
    padding-top: 1rem;
}

.chats-view .inboxes .inbox .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: 5px;
    flex-grow: 1;
    width: calc(100% - 100px);
}

.chats-view .inboxes .inbox .inbox__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.chats-view .inboxes .inbox .inbox__heading h3 {
    color: #000;
    font-family: "Sofia Pro";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.0375rem;
}

.chats-view .inboxes .inbox .inbox__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    gap: 0.5rem;
}

.chats-view .inboxes .inbox .inbox__content p {
    /* truncate exceeding text */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.chats-view .inboxes .inbox .inbox__content>*,
.chats-view .inboxes .inbox .inbox__heading>*,
.chats-view .inboxes .inbox .inbox_time>* {
    margin: 0 !important;
}

.chats-view .inboxes .inbox .inbox_time {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.5rem;
}

.chats-view .inboxes .inbox span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.chats-view .inboxes .inbox span.unread {
    background-color: rgba(var(--vs-primary));
}

.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 3rem;
    padding-left: 20px;
    padding-right: 20px;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

h1 span {
    color: rgba(var(--vs-primary));
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
    margin: 0;
}

.chats-view {
    width: 100%;
    padding: 0 !important;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: white;

}

.chats-view .chats-header {
    background: rgba(var(--vs-primary));
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    margin-top: 1rem;
}

.chats-view .chats-header .vs-button p {
    color: #fff !important;
    margin-left: 5px;
}

.chats-view .chats-header .vs-button.disabled p,
.chats-view .chats-header .vs-button.disabled svg>* {
    opacity: 0.5;
}
</style>
